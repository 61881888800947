import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider
import Navbar from './components/Navbar/Navbar';
import Home from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import Discover from './pages/DiscoverPage';
import KapperPage from './pages/KapperPage';
import PostPage from './pages/PostPage'; // Import the PostPage component
import LandingPage from './pages/LandingPage'; // Import the LandingPage

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <div className="App">
          {/* Navbar at the top */}
          <Navbar />

          {/* Main Content */}
          <div className="main-content">
            <Routes>
              <Route exact path="/" element={<LandingPage />} />
              <Route path="/home" element={<Home />} />
              <Route path="/discover" element={<Discover />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/:username" element={<KapperPage />} />
              <Route path="/post" element={<PostPage />} /> {/* Add the PostPage route */}
            </Routes>
          </div>

          {/* Footer at the bottom */}
          <footer className="footer">
            <p>© 2024 Kapper.io - All Rights Reserved.</p>
          </footer>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

console.log('Google Client ID:', process.env.REACT_APP_GOOGLE_CLIENT_ID);

export default App;
