import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Correct import
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google'; // Import GoogleLogin
import './LoginRegisterModal.css';

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Include cookies
});

// Axios Interceptors for handling token refresh
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { data } = await axios.post(`${API_URL}/auth/refresh`, {}, { withCredentials: true });
        localStorage.setItem('accessToken', data.accessToken);
        originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem('accessToken');
        window.location.href = '/login'; // Redirect to login if refresh fails
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

function LoginRegisterModal({ mode = 'login', closeModal }) {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(mode === 'login');
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setIsLogin(mode === 'login');
  }, [mode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Clear error on input change
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!validateEmail(formData.email)) {
      validationErrors.email = 'Please enter a valid email address.';
    }
    if (!isLogin) {
      if (!validatePassword(formData.password)) {
        validationErrors.password =
          'Password must be at least 8 characters long, include a number and a special character.';
      }
      if (formData.password !== formData.confirmPassword) {
        validationErrors.confirmPassword = 'Passwords do not match.';
      }
      if (!formData.username) {
        validationErrors.username = 'Username is required.';
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const endpoint = isLogin
        ? `${API_URL}/auth/login`
        : `${API_URL}/auth/register`;

      const payload = isLogin
        ? { email: formData.email, password: formData.password }
        : { username: formData.username, email: formData.email, password: formData.password };

      const response = await api.post(endpoint, payload);

      if (isLogin) {
        const { accessToken, user } = response.data;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('username', user.username);
        localStorage.setItem('role', user.role);
        closeModal(); // Close the modal
        navigate('/home'); // Redirect to home page after login
      } else {
        setFormData({ username: '', email: '', password: '', confirmPassword: '' });
        setIsLogin(true);
      }
    } catch (error) {
      setErrors({ general: error.response?.data?.message || 'An error occurred. Please try again.' });
    }
  };

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      const response = await api.post(`${API_URL}/auth/google-login`, { token: credential });

      const { accessToken, user } = response.data;

      // Save user data and token locally
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('username', user.username);
      localStorage.setItem('role', user.role);

      closeModal(); // Close the modal
      navigate('/home'); // Redirect to home page after Google login
    } catch (error) {
      setErrors({ general: 'Failed to log in with Google.' });
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-box">
        <img src="/k.png" alt="Logo" className="modal-logo" />
        <button className="close-icon" onClick={closeModal}>
          &times;
        </button>
        <div className="modal-content">
          <h2>{isLogin ? <strong>Login</strong> : <strong>Register</strong>}</h2>
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={() => alert('Google Login failed')}
            className="google-login-button"
            text={isLogin ? 'Sign in with Google' : 'Sign up with Google'}
          />
          <div className="separator">
            <span>or</span>
          </div>
          <form onSubmit={handleSubmit} className="form">
            {!isLogin && (
              <>
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
                {errors.username && <p className="error-message">{errors.username}</p>}
              </>
            )}
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            {errors.password && <p className="error-message">{errors.password}</p>}
            {!isLogin && (
              <>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                {errors.confirmPassword && (
                  <p className="error-message">{errors.confirmPassword}</p>
                )}
              </>
            )}
            <button type="submit" className="form-submit-button">
              {isLogin ? 'Login' : 'Register'}
            </button>
          </form>
          {errors.general && <p className="error-message">{errors.general}</p>}
          <button
            className="toggle-form-button"
            onClick={() => setIsLogin(!isLogin)}
          >
            {isLogin ? 'Need to Register?' : 'Already have an account? Login'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginRegisterModal;
