import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Kapper.css';

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api';

const STATIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

function Kapper() {
  const { username } = useParams();
  const [kapperDetails, setKapperDetails] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      alert('You must be logged in to view kapper details.');
      return;
    }

    // Fetch Kapper details by username
    axios
      .get(`${API_URL}/kappers/${username}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        setKapperDetails(response.data);
      })
      .catch((error) => {
        console.error('Error fetching kapper details:', error.response?.data || error.message);
      });

    // Check subscription status
    axios
      .get(`${API_URL}/kappers/subscription/${username}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        setIsSubscribed(response.data.is_active);
      })
      .catch((error) => {
        console.error('Error checking subscription status:', error.response?.data || error.message);
      });
  }, [username]);

  const handleSubscribe = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      alert('You must be logged in to subscribe.');
      return;
    }
  
    try {
      const response = await axios.post(
        `${API_URL}/kappers/subscription`,
        { username }, // Ensure username is passed in the body
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setIsSubscribed(response.data.is_active);
      alert(response.data.message);
    } catch (error) {
      console.error('Error toggling subscription:', error.response?.data || error.message);
    }
  };
  

  if (!kapperDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-page">
      <div className="card profile-container">
        <div className="banner">
          <img
            src={
              kapperDetails.banner_picture
                ? `${STATIC_URL}/${kapperDetails.banner_picture}`
                : '/banner.png'
            }
            alt="Banner"
            className="banner-image"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = '/banner.png'; // Fallback to default banner
            }}
          />
        </div>
        <div className="profile-picture-container">
          <img
            src={
              kapperDetails.profile_picture
                ? `${STATIC_URL}/${kapperDetails.profile_picture}`
                : '/profile.png'
            }
            alt="Profile"
            className="profile-picture"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = '/profile.png'; // Fallback to default profile
            }}
          />
        </div>
        <p className="subscribers">Subscribers: {kapperDetails.subscriber_count || 0}</p>
        <h1 className="username">{kapperDetails.username}</h1>
        <p className="bio">{kapperDetails.bio}</p>
        <div className="leagues-section">
          {kapperDetails.leagues?.length > 0 ? (
            <div className="selected-leagues">
              {kapperDetails.leagues.map((league, index) => (
                <span key={index} className="selected-league">
                  {league}
                </span>
              ))}
            </div>
          ) : (
            <p>No leagues selected.</p>
          )}
        </div>
        <div className="subscribe-button-container">
  <button
    className={`subscribe-button ${isSubscribed ? 'subscribed' : ''}`}
    onClick={handleSubscribe}
  >
    {isSubscribed ? 'Subscribed' : 'Subscribe'}
  </button>
</div>
      </div>
      <div className="card about-container">
        <h2>About</h2>
        <p className="about">
          {kapperDetails.about?.length > 100 && !showMore
            ? `${kapperDetails.about.slice(0, 100)}... `
            : kapperDetails.about || 'No additional information provided.'}
          {kapperDetails.about?.length > 100 && (
            <button
              className="btn btn-link"
              onClick={() => setShowMore(!showMore)}
              style={{ display: 'inline', padding: '0', margin: '0', fontSize: 'inherit' }}
            >
              {showMore ? 'Show Less' : 'Show More'}
            </button>
          )}
        </p>
      </div>
    </div>
  );
}

export default Kapper;
