import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import './SettledFeed.css'; // Use the same styling

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api';

const STATIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

function SettledFeed() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSettledFeed = async () => {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        alert('You must be logged in to view your settled plays.');
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/feed/settledFeed`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (Array.isArray(response.data)) {
          setPosts(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
          setError('Unexpected response format');
        }
      } catch (error) {
        console.error('Error fetching settled feed:', error.response?.data || error.message);
        setError('Failed to load settled plays. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSettledFeed();
  }, []);

  const renderBetLegs = (bet) => {
    const legs = bet.split('\n').filter((leg) => leg.trim() !== '');
    return (
      <ul className="feed-bet-legs">
        {legs.map((leg, index) => (
          <li key={index}>{leg}</li>
        ))}
      </ul>
    );
  };

  const renderReactionBar = (post) => {
    const totalReactions = post.total_plays + post.total_passes;
    const playPercentage = totalReactions === 0 ? 50 : (post.total_plays / totalReactions) * 100;
    const passPercentage = 100 - playPercentage;
  
    return (
      <div className="reaction-bar-container">
        <div className="reaction-bar pass-bar" style={{ width: `${passPercentage}%` }}></div>
        <div className="reaction-bar play-bar" style={{ width: `${playPercentage}%` }}></div>
        <span className="feed-post-total pass">Passes: {post.total_passes || 0}</span>
        <span className="feed-post-total play">Plays: {post.total_plays || 0}</span>
      </div>
    );
  };
  

  const calculateOddsBorderColor = (odds) => {
    const minOdds = -2000;
    const maxOdds = 2000;
    const yellow = [255, 255, 0.5];
    const red = [255, 0, 0.5];
    const green = [0, 255, 0.5];

    if (odds >= 0) {
      const ratio = odds / maxOdds;
      const color = yellow.map((component, index) => Math.round(component + ratio * (red[index] - component)));
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    } else {
      const ratio = Math.abs(odds) / Math.abs(minOdds);
      const color = yellow.map((component, index) => Math.round(component + ratio * (green[index] - component)));
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    }
  };

  const renderPostGroups = () => {
    const groupedPosts = posts.reduce((acc, post) => {
      const postDate = new Date(post.created_at);
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);

      let groupLabel;

      if (postDate.toDateString() === today.toDateString()) {
        groupLabel = 'Today';
      } else if (postDate.toDateString() === yesterday.toDateString()) {
        groupLabel = 'Yesterday';
      } else {
        groupLabel = postDate.toLocaleDateString();
      }

      if (!acc[groupLabel]) {
        acc[groupLabel] = [];
      }
      acc[groupLabel].push(post);
      return acc;
    }, {});

    return Object.entries(groupedPosts).map(([label, posts]) => (
      <div key={label} className="feed-post-group">
        <div className="feed-post-divider">
          <hr className="divider-line" />
          <span className="divider-text">{label}</span>
          <hr className="divider-line" />
        </div>
        {posts.map((post) => (
  <div
    key={post.id}
    className={`feed-post-card ${
      post.is_hit === 1 ? 'hit-border' : post.is_hit === 0 ? 'miss-border' : ''
    }`}
  >
    <div className="feed-post-header">
      <div className="feed-post-header-left">
        <img
          src={post.profile_picture ? `${STATIC_URL}/${post.profile_picture}` : '/profile.png'}
          alt={`${post.kapper_username}'s profile`}
          className="feed-profile-picture"
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop
            e.target.src = '/profile.png'; // Fallback to default profile.png
          }}
        />
        <div className="feed-post-header-right">
          <h5 className="feed-post-kapper">{post.kapper_username}</h5>
          <span className="feed-post-date">
            {formatDistanceToNow(new Date(post.created_at), { addSuffix: true })}
          </span>
        </div>
      </div>
      <div className="feed-post-units-league-container">
        <span className="feed-post-league">{post.league_with_emoji}</span>
        <span className="feed-post-units">{post.units} units 💰</span>
      </div>
      <div className="feed-post-odds">
        <div
          className="odds-box"
          style={{ borderColor: calculateOddsBorderColor(post.odds) }}
        >
          {post.odds > 0 ? `+${post.odds}` : post.odds}
        </div>
      </div>
    </div>

    <div className="feed-post-content">
      <h5 className="feed-post-title">{post.title}</h5>
      {renderBetLegs(post.bet)}
      {post.analysis && (
        <div className="feed-post-analysis">
          <strong>Analysis:</strong> {post.analysis}
        </div>
      )}
    </div>

    {/* Add Reaction Bar */}
    {renderReactionBar(post)}
  </div>
))}

      </div>
    ));
  };

  if (loading) {
    return <div className="feed-loading">Loading your settled plays...</div>;
  }

  if (error) {
    return <div className="feed-error">{error}</div>;
  }

  if (posts.length === 0) {
    return <div className="feed-empty">Waiting for your first slip to be settled by the Kapper.</div>;
  }

  return <div className="feed-container">{renderPostGroups()}</div>;
}

export default SettledFeed;
