import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSpring, animated } from 'react-spring';
import { useSwipeable } from 'react-swipeable';
import { formatDistanceToNow } from 'date-fns';
import './KapperFeed.css';

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api';

const STATIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

function KapperFeed() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchKapperFeed = async () => {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        alert('You must be logged in to view your kapper feed.');
        return;
      }

      try {
        console.log('Fetching Kapper Feed...');
        const response = await axios.get(`${API_URL}/feed/kapperFeed`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        console.log('Kapper Feed fetched successfully:', response.data);
        setPosts(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching kapper feed:', error.response?.data || error.message);
        setError('Failed to load feed. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchKapperFeed();
  }, []);

  const handleHitReaction = async (post_id, is_hit) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      console.log(`Updating hit status for post ${post_id} to ${is_hit}`);
      await axios.post(
        `${API_URL}/feed/kapperFeed/updateHit`,
        { post_id, is_hit },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      // Update post state locally without refetching all posts
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === post_id ? { ...post, is_hit } : post
        )
      );
      console.log(`Post ${post_id} updated successfully to is_hit = ${is_hit}`);
    } catch (error) {
      console.error('Error updating hit status:', error.response?.data || error.message);
    }
  };

  const renderBetLegs = (bet) => {
    const legs = bet.split('\n').filter((leg) => leg.trim() !== '');
    return (
      <ul className="feed-bet-legs">
        {legs.map((leg, index) => (
          <li key={index}>{leg}</li>
        ))}
      </ul>
    );
  };

  const calculateOddsBorderColor = (odds) => {
    const minOdds = -2000;
    const maxOdds = 2000;
    const yellow = [255, 255, 0.5];
    const red = [255, 0, 0.5];
    const green = [0, 255, 0.5];

    if (odds >= 0) {
      const ratio = odds / maxOdds;
      const color = yellow.map((component, index) => Math.round(component + ratio * (red[index] - component)));
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    } else {
      const ratio = Math.abs(odds) / Math.abs(minOdds);
      const color = yellow.map((component, index) => Math.round(component + ratio * (green[index] - component)));
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    }
  };

  const SwipeablePostCard = ({ post }) => {
    console.log('Rendering post:', post);

    const [{ x }, api] = useSpring(() => ({
      x: 0,
      config: { tension: 500, friction: 40 },
    }));

    const swipeHandlers = useSwipeable({
      onSwipedLeft: () => {
        if (post.is_hit === null) {
          api.start({
            x: -1000,
            config: { duration: 200 },
            onRest: () => {
              handleHitReaction(post.id, 0); // Set to 0 for "miss"
            },
          });
        }
      },
      onSwipedRight: () => {
        if (post.is_hit === null) {
          api.start({
            x: 1000,
            config: { duration: 200 },
            onRest: () => {
              handleHitReaction(post.id, 1); // Set to 1 for "hit"
            },
          });
        }
      },
      preventScrollOnSwipe: true,
      trackMouse: true,
    });

    // Determine the border color class based on is_hit status
    let borderColorClass = '';
    if (post.is_hit === 1) {
      borderColorClass = 'locked-hit';
      console.log(`Post ${post.id} marked as hit (is_hit = 1): applying green border.`);
    } else if (post.is_hit === 0) {
      borderColorClass = 'locked-miss';
      console.log(`Post ${post.id} marked as miss (is_hit = 0): applying red border.`);
    }

    return (
<animated.div
  className={`feed-post-card ${post.is_hit !== null ? borderColorClass : ''}`}
  style={{ x }}
  {...(post.is_hit === null ? swipeHandlers : {})}
>
<div className="feed-post-header-kapper">
  <span className="feed-post-date-kapper">
    {formatDistanceToNow(new Date(post.created_at), { addSuffix: true })}
  </span>
  <div className="feed-post-header-row">
    <span className="feed-post-league-kapper">{post.league_with_emoji}</span>
    <span className="feed-post-units-kapper">{post.units} units 💰</span>
  </div>
</div>
<div className="feed-post-odds">
  <div
    className="odds-box"
    style={{ borderColor: calculateOddsBorderColor(post.odds) }}
  >
    {post.odds > 0 ? `+${post.odds}` : post.odds}
  </div>
</div>



  <div className="feed-post-content">
    <h5 className="feed-post-title">{post.title}</h5>
    {renderBetLegs(post.bet)}
    {post.analysis && (
      <div className="feed-post-analysis">
        <strong>Analysis:</strong> {post.analysis}
      </div>
    )}
  </div>

  {/* Play/Pass Bar */}
  <div className="reaction-bar-container">
    <div
      className="reaction-bar pass-bar"
      style={{ width: `${(post.total_passes / (post.total_plays + post.total_passes)) * 100 || 50}%` }}
    ></div>
    <div
      className="reaction-bar play-bar"
      style={{ width: `${(post.total_plays / (post.total_plays + post.total_passes)) * 100 || 50}%` }}
    ></div>
    <span className="feed-post-total pass">Passes: {post.total_passes || 0}</span>
    <span className="feed-post-total play">Plays: {post.total_plays || 0}</span>
  </div>
</animated.div>

    );
  };

  if (loading) {
    return <div className="feed-loading">Loading your kapper feed...</div>;
  }

  if (error) {
    return <div className="feed-error">{error}</div>;
  }

  if (posts.length === 0) {
    return <div className="feed-empty">No posts to display. Start posting to see them here!</div>;
  }

  return (
    <div className="feed-container">
      {posts.map((post) => (
        <SwipeablePostCard key={post.id} post={post} />
      ))}
    </div>
  );
}

export default KapperFeed;
