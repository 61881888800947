import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Profile.css';
import '../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCopy } from '@fortawesome/free-solid-svg-icons';

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api';

const STATIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

const MAX_BIO_LENGTH = 100; // Short bio limit
const MAX_ABOUT_LENGTH = 1000; // Longer about section limit
const MAX_LEAGUES = 4; // Maximum number of leagues allowed

function Profile() {
  const [user, setUser] = useState(null);
  const [kapperData, setKapperData] = useState(null);
  const [subscriberCount, setSubscriberCount] = useState(0); // State for subscriber count
  const [isEditing, setIsEditing] = useState(false);
  const [about, setAbout] = useState(''); // State for about section
  const [leagues, setLeagues] = useState([]); // State for selected leagues
  const [leagueOptions, setLeagueOptions] = useState([]); // League options from backend
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false); // State for toggling "Show More"


  const preloadImage = (src) => {
    const img = new Image();
    img.src = src;
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      alert('Please log in to view your profile.');
      navigate('/login');
      return;
    }

    axios
      .get(`${API_URL}/users/profile`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const userData = response.data;

        if (!userData.kapper_id) {
          navigate('/');
          return;
        }

        const bannerSrc = userData.banner_picture
          ? `${STATIC_URL}/${userData.banner_picture}`
          : null;
        const profileSrc = userData.profile_picture
          ? `${STATIC_URL}/${userData.profile_picture}`
          : null;

        if (bannerSrc) preloadImage(bannerSrc);
        if (profileSrc) preloadImage(profileSrc);

        setUser(userData);
        setKapperData({
          banner_picture_path: userData.banner_picture,
          profile_picture_path: userData.profile_picture,
          banner_picture_preview: null,
          profile_picture_preview: null,
          bio: userData.bio,
        });

        setAbout(userData.about || ''); // Set the about section
        setSubscriberCount(userData.subscriber_count || 0);
        setLeagues(userData.leagues || []); // Set selected leagues
        setLeagueOptions(userData.leagueOptions || []); // Set league options from backend
      })
      .catch((error) => {
        console.error('Error fetching profile:', error.response?.data || error.message);
        navigate('/');
      });
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'bio' && value.length <= MAX_BIO_LENGTH) {
      setKapperData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleAboutChange = (e) => {
    if (e.target.value.length <= MAX_ABOUT_LENGTH) {
      setAbout(e.target.value);
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      const previewURL = URL.createObjectURL(file);

      setKapperData((prevData) => ({
        ...prevData,
        [`${name}_preview`]: previewURL,
        [`${name}_file`]: file,
      }));

      return () => {
        URL.revokeObjectURL(previewURL);
      };
    }
  };

  const handleLeagueToggle = (league) => {
    if (leagues.includes(league)) {
      setLeagues(leagues.filter((l) => l !== league));
    } else if (leagues.length < MAX_LEAGUES) {
      setLeagues([...leagues, league]);
    }
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleSave = () => {
    const accessToken = localStorage.getItem('accessToken');
  
    const formData = new FormData();
    if (kapperData.banner_picture_file) {
      formData.append('banner_picture', kapperData.banner_picture_file);
    }
    if (kapperData.profile_picture_file) {
      formData.append('profile_picture', kapperData.profile_picture_file);
    }
    formData.append('bio', kapperData.bio);
    formData.append('about', about);
    formData.append('leagues', JSON.stringify(leagues));
  
    axios
      .put(`${API_URL}/users/profile`, formData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const updatedData = response.data;
  
        // Update kapperData state with new paths
        setKapperData((prevData) => ({
          ...prevData,
          banner_picture_path: updatedData.banner_picture,
          profile_picture_path: updatedData.profile_picture,
          banner_picture_preview: null,
          profile_picture_preview: null,
        }));
  
        // Preload new images to ensure smooth rendering
        if (updatedData.banner_picture) {
          preloadImage(`${STATIC_URL}/${updatedData.banner_picture}`);
        }
        if (updatedData.profile_picture) {
          preloadImage(`${STATIC_URL}/${updatedData.profile_picture}`);
        }
  
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('Error updating profile:', error.response?.data || error.message);
      });
  };
  
  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-page">
      <div className="link-box">
        <div className="link-text">
          <a
            href={`https://kapper.io/${user.username}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            kapper.io/{user.username}
          </a>
        </div>
        <FontAwesomeIcon
          icon={faCopy}
          className="copy-icon"
          onClick={() => {
            navigator.clipboard.writeText(`https://kapper.io/${user.username}`);
            alert('Link copied to clipboard!');
          }}
          style={{ cursor: 'pointer', fontSize: '1.2em' }}
        />
      </div>
      <div className="card profile-container">
        <div
          className={`banner editable ${isEditing ? 'is-editing' : ''}`}
          onClick={() =>
            isEditing && document.querySelector("input[name='banner_picture']").click()
          }
        >
<img
  src={
    kapperData.banner_picture_preview ||
    (kapperData.banner_picture_path
      ? `${STATIC_URL}/${kapperData.banner_picture_path}`
      : '/banner.png') // Fallback to default banner.png
  }
  className="banner-image"
  alt="Banner"
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop
    e.target.src = '/banner.png'; // Fallback to default banner.png
  }}
/>
          <div className="editable-overlay banner-overlay">
            <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" />
          </div>
          <input
            type="file"
            name="banner_picture"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
        <div
          className={`profile-picture-container editable ${isEditing ? 'is-editing' : ''}`}
          onClick={() =>
            isEditing && document.querySelector("input[name='profile_picture']").click()
          }
        >
<img
  src={
    kapperData.profile_picture_preview ||
    (kapperData.profile_picture_path
      ? `${STATIC_URL}/${kapperData.profile_picture_path}`
      : '/profile.png') // Fallback to default profile.png
  }
  className="profile-picture"
  alt="Profile Picture"
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop
    e.target.src = '/profile.png'; // Fallback to default profile.png
  }}
/>
          <div className="editable-overlay">
            <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" />
          </div>
          <input
            type="file"
            name="profile_picture"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
        <div className="subscribers-row">
          <p className="subscribers">Subscribers: {subscriberCount}</p>
          {!isEditing ? (
            <FontAwesomeIcon
  icon={faPencilAlt}
  className="edit-icon-large"
  onClick={() => setIsEditing(true)}
/>
          ) : (
            <div className="button-group">
              <button
                className="btn cancel-button"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>
              <button className="btn save-button" onClick={handleSave}>
                Save
              </button>
            </div>
          )}
        </div>
        <h1 className="username">{user.username}</h1>
        {isEditing ? (
          <>
            <textarea
              className="form-control bio-edit"
              name="bio"
              value={kapperData.bio}
              onChange={handleChange}
            />
            <small className="char-count">
              {kapperData.bio.length}/{MAX_BIO_LENGTH}
            </small>
          </>
        ) : (
          <p className="bio">{kapperData.bio}</p>
        )}
        <div className="leagues-section">
          {isEditing ? (
            <div className="league-options">
              {leagueOptions.map((option) => (
                <button
                  key={option.name}
                  className={`league-button ${leagues.includes(option.name) ? 'selected' : ''}`}
                  onClick={() => handleLeagueToggle(option.name)}
                >
                  {option.emoji} {option.name}
                </button>
              ))}
            </div>
          ) : (
            <div className="selected-leagues">
              {leagues.map((league) => (
                <span key={league} className="selected-league">
                  {leagueOptions.find((option) => option.name === league)?.emoji} {league}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="card about-container">
  <h2>About</h2>
  {isEditing ? (
    <>
      <textarea
        className="form-control about-edit"
        name="about"
        value={about}
        onChange={handleAboutChange}
      />
      <small className="char-count">
        {about.length}/{MAX_ABOUT_LENGTH}
      </small>
    </>
  ) : (
    <p className="about">
      {about.length > 100 && !showMore
        ? `${about.slice(0, 100)}... `
        : about}
      {about.length > 100 && (
        <button
          className="btn btn-link"
          onClick={toggleShowMore}
          style={{ display: 'inline', padding: '0', margin: '0', fontSize: 'inherit' }}
        >
          {showMore ? 'Show Less' : 'Show More'}
        </button>
      )}
    </p>
  )}
</div>

    </div>
  );
}

export default Profile;
