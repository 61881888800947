// Discover.js (Frontend - React.js)
import React from 'react';
import Kappers from '../components/Kappers/Kappers';


function Discover() {
  return (
    <div className="discover-container">
      {/* Add the Kappers component here */}
      <Kappers />
    </div>
  );
}

export default Discover;
