import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import LoginRegisterModal from '../LoginRegisterModal/LoginRegisterModal';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faMagnifyingGlass,
  faUser,
  faPencil,
  faBars,
  faTimes,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = !!localStorage.getItem('accessToken');
  const role = localStorage.getItem('role');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('login');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);

      if (isSidebarOpen) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSidebarOpen]);

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    navigate('/');
    setIsSidebarOpen(false);
  };

  const openModal = (mode) => {
    setModalMode(mode);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setIsSidebarOpen(true),
    onSwipedRight: () => setIsSidebarOpen(false),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  return (
    <>
      <nav className={`navbar ${isScrolled ? 'navbar-scrolled' : ''}`}>
        <div className="navbar-left">
          <Link to="/" className="navbar-logo">
            <img src={isScrolled ? 'k.png' : 'Kapper.png'} alt="Kapper Logo" className="navbar-logo-img" />
          </Link>
        </div>
        <ul className="nav-links">
          {isLoggedIn && (
            <>
              <li>
                <Link to="/home">
<FontAwesomeIcon
  icon={faHome}
  className={`nav-icon ${location.pathname === '/home' ? 'active' : ''} ${
    isSidebarOpen ? 'sidebar-open' : ''
  }`}
/>
                </Link>
              </li>
              <li>
                <Link to="/discover">
                <FontAwesomeIcon
  icon={faMagnifyingGlass}
  className={`nav-icon fa-flip-horizontal ${
    location.pathname === '/discover' ? 'active' : ''
  } ${isSidebarOpen ? 'sidebar-open' : ''}`}
/>
                </Link>
              </li>
              {role === 'kapper' && (
                <li>
                  <Link to="/post">
                  <FontAwesomeIcon
  icon={faPencil}
  className={`nav-icon ${location.pathname === '/post' ? 'active' : ''} ${
    isSidebarOpen ? 'sidebar-open' : ''
  }`}
/>
                  </Link>
                </li>
              )}
              {role === 'kapper' && (
                <li>
                  <Link to="/profile">
                  <FontAwesomeIcon
  icon={faUser}
  className={`nav-icon ${location.pathname === '/profile' ? 'active' : ''} ${
    isSidebarOpen ? 'sidebar-open' : ''
  }`}
/>
                  </Link>
                </li>
              )}
              <li className="sidebar-toggle">
                <button onClick={toggleSidebar} className="nav-sidebar-button">
                <FontAwesomeIcon
  icon={isSidebarOpen ? faTimes : faBars}
  className={`nav-icon ${isSidebarOpen ? 'sidebar-active' : ''}`}
/>
                </button>
              </li>
            </>
          )}
          {!isLoggedIn && (
            <>
              <li>
                <Link to="#" className="nav-login-link" onClick={() => openModal('login')}>
                  Login
                </Link>
              </li>
              <li>
                <button className="nav-signup-button" onClick={() => openModal('register')}>
                  Sign Up
                </button>
              </li>
            </>
          )}
        </ul>
      </nav>
      {isSidebarOpen && (
        <div
          className={`sidebar-overlay ${isSidebarOpen ? 'sidebar-open' : ''}`}
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
      <div
        className={`sidebar ${isSidebarOpen ? 'open' : ''}`}
        {...swipeHandlers}
      >
        <ul className="sidebar-menu">
          <li>
            <Link to="/future-page-1" onClick={toggleSidebar}>
              Future Page 1
            </Link>
          </li>
          <li>
            <Link to="/future-page-2" onClick={toggleSidebar}>
              Future Page 2
            </Link>
          </li>
        </ul>
        <div className="sidebar-logout">
          <button className="logout-link" onClick={handleLogout}>
            <FontAwesomeIcon icon={faRightFromBracket} className="logout-icon" />
            Logout
          </button>
        </div>
      </div>
      {isModalOpen && <LoginRegisterModal mode={modalMode} closeModal={closeModal} />}
    </>
  );
}

export default Navbar;
