import axios from 'axios';

// Set the API URL based on the environment
const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api';

const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Required for cookies
});

apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
  
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Prevent infinite loops
  
        console.log('401 Unauthorized detected. Attempting token refresh...');
        try {
          const refreshResponse = await axios.post(`${API_URL}/refresh`, null, {
            withCredentials: true, // Send cookies
          });
  
          console.log('Refresh successful:', refreshResponse.data);
  
          const newAccessToken = refreshResponse.data.accessToken;
          localStorage.setItem('accessToken', newAccessToken);
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
  
          return apiClient(originalRequest); // Retry original request
        } catch (refreshError) {
          console.error('Error during token refresh:', refreshError);
          localStorage.removeItem('accessToken'); // Cleanup
          window.location.href = '/login'; // Redirect to login
        }
      }
  
      return Promise.reject(error);
    }
  );
   

console.log('Access Token:', localStorage.getItem('accessToken'));
console.log('API_URL:', API_URL);

export default apiClient;
