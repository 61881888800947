import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Kappers.css';

// Define API_URL and STATIC_URL based on the environment
const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api';

const STATIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

function Kappers() {
  const [kappers, setKappers] = useState([]); // Initialize as an empty array
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_URL}/kappers`) // Adjusted to match the correct backend route
      .then((response) => {
        // Ensure the response data is an array
        if (Array.isArray(response.data)) {
          setKappers(response.data);
        } else {
          console.error('Unexpected API response:', response.data);
          setKappers([]); // Fallback to an empty array
        }
      })
      .catch((error) => {
        console.error('Error fetching kappers:', error);
        setKappers([]); // Fallback to an empty array
      });
  }, []);

  const handleCardClick = (username) => {
    navigate(`/${username}`); // Use username for navigation
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Safely filter kappers
  const filteredKappers = Array.isArray(kappers)
    ? kappers.filter((kapper) =>
        kapper.username.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <div className="kappers-container">
      <div className="search-bar-container">
        <input
          type="text"
          placeholder="Search Kappers..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-bar"
        />
      </div>
      <div className="kappers-grid">
        {filteredKappers.length > 0 ? (
          filteredKappers.map((kapper) => (
            <div
              className="kapper-card"
              key={kapper.kapper_id}
              onClick={() => handleCardClick(kapper.username)} // Use username here
            >
              <img
                src={`${STATIC_URL}/${kapper.profile_picture}`}
                alt={`${kapper.username}'s profile`}
                className="kapper-profile-picture"
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src = '/profile.png'; // Fallback to default profile picture
                }}
              />
              <h5 className="kapper-username">{kapper.username}</h5>
            </div>
          ))
        ) : (
          <p>No kappers found. Please try again later.</p>
        )}
      </div>
    </div>
  );
}

export default Kappers;
