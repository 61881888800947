import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './CreatePost.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';

Modal.setAppElement('#root'); // Accessibility requirement

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api';

function CreatePost() {
  const [isPublic, setIsPublic] = useState(false);
  const [title, setTitle] = useState('');
  const [units, setUnits] = useState(0.25);
  const [odds, setOdds] = useState(0);
  const [betLegs, setBetLegs] = useState(['']);
  const [league, setLeague] = useState('');
  const [analysis, setAnalysis] = useState('');
  const [message, setMessage] = useState('');
  const [kapperId, setKapperId] = useState(null);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const calculateOddsBorderColor = (odds) => {
    const minOdds = -2000;
    const maxOdds = 2000;
    const yellow = [255, 255, 0.5];
    const red = [255, 0, 0.5];
    const green = [0, 255, 0.5];

    if (odds >= 0) {
      const ratio = odds / maxOdds;
      const color = yellow.map((component, index) =>
        Math.round(component + ratio * (red[index] - component))
      );
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    } else {
      const ratio = Math.abs(odds) / Math.abs(minOdds);
      const color = yellow.map((component, index) =>
        Math.round(component + ratio * (green[index] - component))
      );
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    }
  };

  const leagues = [
    { name: 'NFL', emoji: '🏈' },
    { name: 'NBA', emoji: '🏀' },
    { name: 'MLB', emoji: '⚾' },
    { name: 'NHL', emoji: '🏒' },
    { name: 'NCAAF', emoji: '🎓🏈' },
    { name: 'NCAAB', emoji: '🎓🏀' },
    { name: 'MLS', emoji: '⚽' },
    { name: 'EPL', emoji: '🇬🇧⚽' },
    { name: 'LaLiga', emoji: '🇪🇸⚽' },
    { name: 'SerieA', emoji: '🇮🇹⚽' },
    { name: 'UFC', emoji: '🥋' },
    { name: 'Boxing', emoji: '🥊' },
    { name: 'PGA', emoji: '⛳' },
    { name: 'ATP', emoji: '🎾' },
    { name: 'NASCAR', emoji: '🏎️' },
    { name: 'WNBA', emoji: '🏀' },
    { name: 'CFL', emoji: '🇨🇦🏈' },
    { name: 'Cricket', emoji: '🏏' },
    { name: 'Esports', emoji: '🎮' },
    { name: 'F1', emoji: '🏁' },
  ];

  const half = Math.ceil(leagues.length / 2);
  const leagueRow1 = leagues.slice(0, half);
  const leagueRow2 = leagues.slice(half);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      alert('You must be logged in to create a post.');
      navigate('/login');
      return;
    }

    axios
      .get(`${API_URL}/users/profile`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const userData = response.data;

        if (!userData.kapper_id) {
          alert('You must be a kapper to create a post.');
          navigate('/');
          return;
        }

        setKapperId(userData.kapper_id);
      })
      .catch((error) => {
        console.error('Error fetching profile:', error.response?.data || error.message);
        alert('Error fetching profile. Please try again later.');
        navigate('/');
      });
  }, [navigate]);

  const handleAddLeg = () => {
    if (betLegs.length < 4) {
      setBetLegs([...betLegs, '']);
    }
  };

  const handleRemoveLeg = (index) => {
    if (betLegs.length === 1) return;
    setBetLegs(betLegs.filter((_, i) => i !== index));
  };

  const handleLegChange = (index, value) => {
    const updatedBetLegs = [...betLegs];
    updatedBetLegs[index] = value;
    setBetLegs(updatedBetLegs);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!league) {
      setError('You must select a league.');
      return;
    }

    if (!kapperId) {
      alert('Kapper ID not found. Please log in again.');
      return;
    }

    try {
      const bet = betLegs.filter((leg) => leg.trim() !== '').join('\n');
      const response = await axios.post(`${API_URL}/posts`, {
        kapper_id: kapperId,
        title,
        units,
        odds,
        bet,
        league,
        analysis,
        is_public: isPublic,
      });

      setMessage(response.data.message);
      setTitle('');
      setUnits(0.25);
      setOdds(0);
      setBetLegs(['']);
      setLeague('');
      setAnalysis('');
      setIsPublic(false);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error creating post:', error.response?.data || error.message);
      setMessage('Failed to create post. Please try again.');
    }
  };

  return (
    <div>
      <button
        onClick={() => setIsModalOpen(true)}
        className="create-post-open-button"
      >
        <span className="create-post-button-text">New Slip 📝</span>
        <span className="create-post-button-icon">+</span>
      </button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Create Post"
        className="create-post-modal-content"
        overlayClassName="create-post-modal-overlay"
        style={{ content: { height: '650px', overflowY: 'auto' } }}
      >
        <div className="create-post-modal-header">
          <img src="/k.png" alt="Logo" className="create-post-modal-logo" />
          <span
            className="create-post-modal-close"
            onClick={() => setIsModalOpen(false)}
          >
            ×
          </span>
        </div>
        <h2 className="create-post-modal-title">.</h2>
        {message && <p className="create-post-modal-alert alert-info">{message}</p>}
        {error && <p className="create-post-modal-alert alert-danger">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div>
          <div
  className={`visibility-toggle ${
    isPublic ? 'public-slip' : 'subscriber-slip'
  }`}
  onClick={() => setIsPublic(!isPublic)}
>
  {isPublic ? 'Public Slip' : 'Subscriber Slip'}
</div>
            <div className="create-post-league-row">
              {leagueRow1.map((lg) => (
                <button
                  type="button"
                  key={lg.name}
                  onClick={() => setLeague(lg.name)}
                  className={`create-post-league-button ${
                    league === lg.name ? 'selected' : ''
                  }`}
                >
                  {lg.name} {lg.emoji}
                </button>
              ))}
            </div>
            <div className="create-post-league-row">
              {leagueRow2.map((lg) => (
                <button
                  type="button"
                  key={lg.name}
                  onClick={() => setLeague(lg.name)}
                  className={`create-post-league-button ${
                    league === lg.name ? 'selected' : ''
                  }`}
                >
                  {lg.name} {lg.emoji}
                </button>
              ))}
            </div>
            <div className="section" style={{ marginTop: '30px' }}>
  <label htmlFor="title" className="section-label">Title</label>
  <input
    type="text"
    id="title"
    value={title}
    onChange={(e) => setTitle(e.target.value)}
    className="form-control"
    required
  />
</div>
<div className="section">
  <label className="section-label">Play</label>
  {betLegs.map((leg, index) => (
    <div key={index} className="leg-row">
      <input
        type="text"
        value={leg}
        onChange={(e) => handleLegChange(index, e.target.value)}
        className={`form-control leg-input ${
          index > 0 ? 'short-leg-input' : ''
        }`}
        placeholder={`Leg ${index + 1}`}
        required
      />
      {index > 0 && (
        <span
          className="remove-leg-btn"
          onClick={() => handleRemoveLeg(index)}
        >
          ✕
        </span>
      )}
    </div>
  ))}
  {betLegs.length < 4 && (
    <span
      className="add-leg-btn"
      onClick={handleAddLeg}
    >
      + Add Leg
    </span>
  )}
</div>
<div className="section">
  <label htmlFor="analysis" className="section-label">Analysis</label>
  <textarea
    id="analysis"
    value={analysis}
    onChange={(e) => setAnalysis(e.target.value)}
    className="form-control"
    placeholder="Enter your analysis"
  ></textarea>
</div>

            <div className="slider-container">
              <div className="slider-group">
                <label htmlFor="odds" className="slider-header">
                  Odds: {odds > 0 ? `+${odds}` : odds}
                </label>
                <input
                  type="range"
                  id="odds"
                  value={odds}
                  onChange={(e) => setOdds(parseInt(e.target.value))}
                  className="slider"
                  min="-2000"
                  max="2000"
                  step="10"
                  style={{ '--thumb-color': calculateOddsBorderColor(odds) }}
                />
              </div>
              <div className="slider-group">
                <label htmlFor="units" className="slider-header">
                  Units: {units}
                </label>
                <input
                  type="range"
                  id="units"
                  value={units}
                  onChange={(e) => setUnits(parseFloat(e.target.value))}
                  className="slider"
                  min="0.25"
                  max="10"
                  step="0.25"
                />
              </div>
            </div>
          </div>
          <div className="create-post-modal-button-container">
  <button type="submit" className="create-post-modal-button full-width">
    Post Slip
  </button>
</div>
<div className="disclaimer">* Slips cannot be edited or removed once posted</div>
        </form>
      </Modal>
    </div>
  );
}

export default CreatePost;
