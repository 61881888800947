import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './CreateAlert.css';

Modal.setAppElement('#root'); // Accessibility requirement

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api';

function CreateAlert() {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [message, setMessage] = useState('');
  const [kapperId, setKapperId] = useState(null);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // Fetch user profile to get kapper_id
  useEffect(() => {
    const fetchProfile = async () => {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        alert('You must be logged in to create an alert.');
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/users/profile`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        const userData = response.data;

        console.log('Fetched User Data:', userData); // Debugging line
        if (!userData.kapper_id) {
          alert('You must be a kapper to create an alert.');
          navigate('/');
          return;
        }

        setKapperId(userData.kapper_id);
      } catch (err) {
        console.error('Error fetching profile:', err.response?.data || err.message);
        alert('Error fetching profile. Please try again later.');
        navigate('/');
      }
    };

    fetchProfile();
  }, [navigate]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!kapperId) {
      alert('Kapper ID not found. Please log in again.');
      return;
    }

    if (!title || !body) {
      setError('Both title and body are required.');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/alerts`, {
        kapper_id: kapperId,
        title,
        body,
      });

      setMessage(response.data.message);
      setTitle('');
      setBody('');
      setIsModalOpen(false);
    } catch (err) {
      console.error('Error creating alert:', err.response?.data || err.message);
      setError('Failed to create alert. Please try again.');
    }
  };

  return (
    <div>
      <button
        onClick={() => setIsModalOpen(true)}
        className="create-alert-open-button"
      >
        <span className="create-alert-button-text">New Alert 🚨</span>
        <span className="create-alert-button-icon">+</span>
      </button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Create Alert"
        className="create-alert-modal-content"
        overlayClassName="create-alert-modal-overlay"
      >
        <div className="create-alert-modal-header">
          <img src="/k.png" alt="Logo" className="create-alert-modal-logo" />
          <span
            className="create-alert-modal-close"
            onClick={() => setIsModalOpen(false)}
          >
            ×
          </span>
        </div>
        <h2 className="create-alert-modal-title">.</h2>
        {message && <p className="create-alert-modal-alert alert-info">{message}</p>}
        {error && <p className="create-alert-modal-alert alert-danger">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="section">
            <label htmlFor="title" className="section-label">Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="section">
            <label htmlFor="body" className="section-label">Body</label>
            <textarea
              id="body"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              className="form-control"
              required
            ></textarea>
          </div>
          <div className="create-alert-modal-button-container">
            <button type="submit" className="create-alert-modal-button full-width">
              Post Alert
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default CreateAlert;
