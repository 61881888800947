import React from 'react';
import Kapper from '../components/Kapper/Kapper'; // Adjust the path based on your folder structure

function KapperPage() {
  return (
    <div className="kapper-page-container">
      <Kapper />
    </div>
  );
}

export default KapperPage;
