import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSpring, animated, useTransition } from 'react-spring';
import { useSwipeable } from 'react-swipeable';
import { formatDistanceToNow } from 'date-fns';
import apiClient from '../../services/apiClient';
import './SubscriberFeed.css';

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api';

const STATIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

function SubscriberFeed() {
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFeed = async () => {
      try {
        const response = await apiClient.get('/feed', {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        });
    
        const fetchedPosts = response.data.posts || [];
        const fetchedAlerts = response.data.alerts || [];
        const combinedFeed = [...fetchedPosts, ...fetchedAlerts].sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
    
        setFeed(combinedFeed);
      } catch (error) {
        console.error('Error fetching feed:', error.response?.data || error.message);
        setError('Failed to load feed. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchFeed();
  }, []);  


  const groupFeedByDate = (feed) => {
    return feed.reduce((acc, item) => {
      const itemDate = new Date(item.created_at);
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
  
      let groupLabel;
      if (itemDate.toDateString() === today.toDateString()) {
        groupLabel = 'Today';
      } else if (itemDate.toDateString() === yesterday.toDateString()) {
        groupLabel = 'Yesterday';
      } else {
        groupLabel = itemDate.toLocaleDateString();
      }
  
      if (!acc[groupLabel]) {
        acc[groupLabel] = [];
      }
      acc[groupLabel].push(item);
      return acc;
    }, {});
  };  

  const UnifiedCard = ({ item }) => {
    if (item.post_id) {
      return <SwipeablePostCard post={item} />;
    } else {
      return <AlertCard alert={item} onDismiss={handleDismissAlert} />;
    }
  };  

  const CardGroup = ({ items }) => {
    return items.map((item) => <UnifiedCard key={item.post_id || item.alert_id} item={item} />);
  };

  const handleReaction = async (post_id, is_play) => {
    try {
      await apiClient.post(
        '/reactions/react',
        { post_id, is_play },
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      );
  
      setFeed((prevFeed) =>
        prevFeed.map((item) =>
          item.post_id === post_id
            ? {
                ...item,
                user_reaction: is_play,
                total_plays: is_play ? item.total_plays + 1 : item.total_plays,
              }
            : item
        )
      );
    } catch (error) {
      console.error('Error sending reaction:', error.response?.data || error.message);
    }
  };
  

  const renderBetLegs = (bet) => {
    const legs = bet.split('\n').filter((leg) => leg.trim() !== '');
    return (
      <ul className="feed-bet-legs">
        {legs.map((leg, index) => (
          <li key={index}>{leg}</li>
        ))}
      </ul>
    );
  };

  const calculateOddsBorderColor = (odds) => {
    const minOdds = -2000;
    const maxOdds = 2000;
    const yellow = [255, 255, 0.5];
    const red = [255, 0, 0.5];
    const green = [0, 255, 0.5];

    if (odds >= 0) {
      const ratio = odds / maxOdds;
      const color = yellow.map((component, index) => Math.round(component + ratio * (red[index] - component)));
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    } else {
      const ratio = Math.abs(odds) / Math.abs(minOdds);
      const color = yellow.map((component, index) => Math.round(component + ratio * (green[index] - component)));
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    }
  };

  const SwipeablePostCard = ({ post }) => {
    const [{ x, opacity }, api] = useSpring(() => ({
      x: 0,
      opacity: 1,
      config: { tension: 250, friction: 30 },
    }));
  
    const swipeHandlers = useSwipeable({
      onSwipedLeft: () => {
        if (post.user_reaction === null) {
          api.start({
            x: -1000,
            config: { tension: 180, friction: 20 },
            onRest: async () => {
              try {
                await handleReaction(post.post_id, false); // Mark as "pass"
                setFeed((prevFeed) => prevFeed.filter((item) => item.post_id !== post.post_id)); // Remove post
              } catch (error) {
                console.error('Error reacting to post:', error);
                api.start({ x: 0, config: { tension: 250, friction: 20 } }); // Bounce back on error
              }
            },
          });
        }
      },          
      onSwipedRight: () => {
        if (post.user_reaction === null) {
          api.start({
            x: 1000,
            opacity: 0,
            config: { tension: 200, friction: 20 },
            onRest: async () => {
              try {
                await handleReaction(post.post_id, true); // Mark as "play"
              } catch (error) {
                console.error('Error reacting to post:', error);
              }
              api.start({
                x: 0,
                opacity: 1,
                config: { tension: 250, friction: 20 },
              });
            },
          });
        }
      },        
      preventScrollOnSwipe: true,
      trackMouse: true,
    });
  
    const totalReactions = post.total_plays + post.total_passes;
    const playPercentage = totalReactions === 0 ? 50 : (post.total_plays / totalReactions) * 100;
    const passPercentage = 100 - playPercentage;
  
    return (
      <animated.div
        className={`feed-post-card ${
          post.user_reaction === true || post.is_play ? 'locked-play' : post.user_reaction === false ? 'pass' : ''
        }`}
        style={{ x, opacity }}
        {...(post.user_reaction === null ? swipeHandlers : {})}
      >
        <div className="feed-post-header">
          <div className="feed-post-header-left">
          <img
  src={
    post.profile_picture
      ? `${STATIC_URL}/${post.profile_picture}`
      : '/profile.png'
  }
  alt={`${post.kapper_username}'s profile`}
  className="feed-profile-picture"
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop
    e.target.src = '/profile.png'; // Fallback to default profile.png
  }}
/>
            <div className="feed-post-header-right">
              <h5 className="feed-post-kapper">{post.kapper_username}</h5>
              <span className="feed-post-date">
                {formatDistanceToNow(new Date(post.created_at), { addSuffix: true })}
              </span>
            </div>
          </div>
          <div className="feed-post-units-league-container">
            <span className="feed-post-league">{post.league_with_emoji}</span>
            <span className="feed-post-units">{post.units} units 💰</span>
          </div>
          <div className="feed-post-odds">
            <div
              className="odds-box"
              style={{ borderColor: calculateOddsBorderColor(post.odds) }}
            >
              {post.odds > 0 ? `+${post.odds}` : post.odds}
            </div>
          </div>
        </div>
  
        <div className="feed-post-content">
          <h5 className="feed-post-title">{post.title}</h5>
          {renderBetLegs(post.bet)}
          {post.analysis && (
            <div className="feed-post-analysis">
              <strong>Analysis:</strong> {post.analysis}
            </div>
          )}
        </div>
        <div className="reaction-bar-container">
          <div className="reaction-bar pass-bar" style={{ width: `${passPercentage}%` }}></div>
          <div className="reaction-bar play-bar" style={{ width: `${playPercentage}%` }}></div>
          <span className="feed-post-total pass">Passes: {post.total_passes || 0}</span>
          <span className="feed-post-total play">Plays: {post.total_plays || 0}</span>
        </div>
      </animated.div>
    );
  };
  
  const handleDismissAlert = (alertId) => {
    setFeed((prevFeed) => prevFeed.filter((item) => item.alert_id !== alertId));
  };  

  const AlertCard = ({ alert, onDismiss }) => {
    const handleDismiss = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        console.log('Sending dismiss request with alert ID:', alert.alert_id); // Log correct alert_id
  
        if (!alert.alert_id) {
          console.error('Alert ID is missing or undefined');
          return;
        }
  
        const response = await axios.post(`${API_URL}/alerts/dismiss`, { alert_id: alert.alert_id }, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        console.log('Dismiss response:', response.data);
        onDismiss(alert.alert_id); // Pass alert_id to the parent
      } catch (error) {
        console.error('Error dismissing alert:', error.response?.data || error.message);
      }
    };
  
    return (
      <div className="feed-post-card">
        <div className="feed-post-header">
          <div className="feed-post-header-left">
          <img
  src={
    alert.profile_picture
      ? `${STATIC_URL}/${alert.profile_picture}`
      : '/profile.png'
  }
  alt={`${alert.kapper_username}'s profile`}
  className="feed-profile-picture"
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop
    e.target.src = '/profile.png'; // Fallback to default profile.png
  }}
/>
            <div className="feed-post-header-right">
              <h5 className="feed-post-kapper">{alert.kapper_username}</h5>
              <span className="feed-post-date">
                {formatDistanceToNow(new Date(alert.created_at), { addSuffix: true })}
              </span>
            </div>
          </div>
          <button className="dismiss-alert-button" onClick={handleDismiss}>
            X
          </button>
        </div>
  
        <div className="feed-post-content">
          <h5 className="feed-post-title">{alert.title}</h5>
          <p className="feed-bet-legs">{alert.body}</p>
        </div>
      </div>
    );
  };  
  
  if (loading) {
    return <div className="feed-loading">Loading your feed...</div>;
  }

  if (error) {
    return <div className="feed-error">{error}</div>;
  }

  if (feed.length === 0) {
    return <div className="feed-empty">No new subscription slips or alerts.</div>;
  }

  return (
    <div className="feed-container">
      {Object.entries(groupFeedByDate(feed)).map(([label, items]) => (
        <div key={label} className="feed-post-group">
          <div className="feed-post-divider">
            <hr className="divider-line" />
            <span className="divider-text">{label}</span>
            <hr className="divider-line" />
          </div>
          <CardGroup items={items} />
        </div>
      ))}
    </div>
  );  
}


export default SubscriberFeed;
