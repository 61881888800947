import React, { useState } from 'react';
import SubscriberFeed from '../components/SubscriberFeed/SubscriberFeed'; // Adjust the path
import PublicFeed from '../components/PublicFeed/PublicFeed'; // Adjust the path
import SettledFeed from '../components/SettledFeed/SettledFeed'; // Adjust the path
import './HomePage.css';

function HomePage() {
  const [activeTab, setActiveTab] = useState('subscriptions'); // Default to 'subscriptions'

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top on tab change
  };

  return (
    <div className="home-page">
      {/* Top Tab Menu */}
      <div className="top-tab-menu">
        <h2
          className={`top-tab ${activeTab === 'public' ? 'active' : ''}`}
          onClick={() => handleTabClick('public')}
        >
          Public
        </h2>
        <h2
          className={`top-tab subscriptions ${activeTab === 'subscriptions' ? 'active' : ''}`}
          onClick={() => handleTabClick('subscriptions')}
        >
          Subscriptions
        </h2>
        <h2
          className={`top-tab ${activeTab === 'settled' ? 'active' : ''}`}
          onClick={() => handleTabClick('settled')}
        >
          Settled
        </h2>
      </div>

      {/* Content Section */}
      <div className="tab-content">
        {activeTab === 'public' && <PublicFeed />}
        {activeTab === 'subscriptions' && <SubscriberFeed />}
        {activeTab === 'settled' && <SettledFeed />}
      </div>
    </div>
  );
}

export default HomePage;
