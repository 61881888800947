// ProfilePage.js (Frontend - React.js)
import React from 'react';
import Profile from '../components/Profile/Profile';

function ProfilePage() {
  return (
    <div className="profile-page">
      <Profile />
    </div>
  );
}

export default ProfilePage;
