import React from 'react';
import CreatePost from '../components/CreatePost/CreatePost'; // Adjust the path based on your file structure
import CreateAlert from '../components/CreateAlert/CreateAlert'; // Adjust the path based on your file structure
import KapperFeed from '../components/KapperFeed/KapperFeed'; // Adjust the path based on your file structure
import './PostPage.css'

function PostPage() {
  return (
    <div className="post-page-container">
      <CreatePost />
      <CreateAlert />
      <KapperFeed />
    </div>
  );
}

export default PostPage;
